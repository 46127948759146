var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "title font-weight-light text-center mt-3",
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v("Historikk - Kommer")]);
}
var staticRenderFns = []

export { render, staticRenderFns }